import * as React from 'react'
import { format, parseISO } from 'date-fns'

import { Table } from '@/components/Table'
import { AnchorButton } from '@/components/Button'
import AddOutlinedIcon from '@material-ui/icons/AddOutlined'
import SettingsOutlinedIcon from '@material-ui/icons/SettingsOutlined'
import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf'
import TableChartIcon from '@mui/icons-material/TableChart'
import AttachmentIcon from '@material-ui/icons/Attachment'
import DeleteOutlined from '@material-ui/icons/DeleteOutlined'
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp'
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown'
import type { Property } from '@/types/property'
import { LoadingOverlay } from '@/components/Loading/LoadingOverlay'
import { User } from '@/types/user'
import * as PropertyDocumentAction from '@/actions/PropertyDocumentAction'
import { redirect } from '@/utils/errors'

const PropertyDocuments: React.FC<{
  property: Property
  property_documents?: { [key: string]: string }
  user: User
  authenticity_token: string
}> = ({ property, property_documents, user, authenticity_token }) => {

  const [propertyDocuments, setPropertyDocuments] = React.useState(property_documents)
  const [sortType, setSortType] = React.useState<sortTypeEnum>('date-desc')
  const [isLoading, setIsLoading] = React.useState(false)


  React.useEffect(() => {
    sortPropertyDocuments(sortType)
  }, [sortType])

  const sort = (columns: string[], direction: string) => {
    return direction == 'asc' ?
      columns[0] > columns[1] ? 1 : -1 :
      columns[0] < columns[1] ? 1 : -1
  }

  const sortByInt = (columns: string[], direction: string) => {
    const [n1, n2] = [parseInt(columns[0]), parseInt(columns[1])]
    return (n1 === NaN || n2 === NaN) ?
      sort([columns[0], columns[1]], direction) :
      sort([n1, n2], direction)
  }

  const sortPropertyDocuments = (sortType: sortTypeEnum) => {
    setPropertyDocuments(
      Array.from(propertyDocuments).sort((n1, n2) => {
        const [column, direction] = sortType.split('-')
        switch(column) {
          case 'name':
            return sortByInt(n1.name, n2.name, direction)
            break;
          case 'format_name':
            return sortByInt(n1.format_name, n2.format_name, direction)
            break;
          case 'user_name':
            return sortByInt(n1.user_name, n2.user_name, direction)
            break;
          case 'date':
            return sort([n1.created_at, n2.created_at], direction)
            break;
          default:
            return 0
        }
      })
    )
  }

  const pdfDownloadPropertyDocument = async (id: number) => {
    const Item = propertyDocuments.find((propertyDocument) => propertyDocument.id === id)
    if (!confirm(`帳票「${Item.name}」をダウンロードしますか？`)) {
      return
    }
    try {
      setIsLoading(true)
      const blob = await PropertyDocumentAction.downloadDocument(id)
      const downloadTag = document.createElement('a')
      console.log(blob)
      downloadTag.href = URL.createObjectURL(blob)
      downloadTag.download = `${Item.name}.pdf`
      downloadTag.click()
    } catch(error) {
      const params = { id: property.hashid, tab: 'property_documents'}
      redirect(error, '/property_documents/index_with_notice', 'ダウンロードに失敗しました。', params)
    } finally {
      setIsLoading(false); // スピナーを停止
    }
  }

  const excelDownloadPropertyDocument = async (id: number) => {
    const Item = propertyDocuments.find((propertyDocument) => propertyDocument.id === id)
    if (!confirm(`帳票「${Item.name}」のExcelをダウンロードしますか？`)) {
      return
    }
    try {
      const blob = await PropertyDocumentAction.downloadExcel(id)
      const downloadTag = document.createElement('a')
      downloadTag.href = URL.createObjectURL(blob)
      downloadTag.download = `${Item.name}.xlsx`
      downloadTag.click()
    } catch (error) {
      const params = { id: property.hashid, tab: 'property_documents' }
      redirect(
        error,
        '/property_documents/index_with_notice',
        'ダウンロードに失敗しました。',
        params
      )
    }
  }

  const deletePropertyDocument = React.useCallback(
    async (id: number) => {
      const deleteItem = propertyDocuments.find((propertyDocument) => propertyDocument.id === id)
      if (!confirm(deleteItem.name + 'を削除してもよろしいですか？' + deleteItem.public_file_detail_id && '\n 公開ページも削除されます')) {
        return
      }
      try {
        await PropertyDocumentAction.deleteDocument(id)
        const newPropertyDocuments = [...propertyDocuments]
        const deleteIndex = propertyDocuments.findIndex((propertyDocument) => propertyDocument.id === id)
        newPropertyDocuments.splice(deleteIndex, 1)
        setPropertyDocuments(newPropertyDocuments)
      } catch(error) {
        const params = { id: property.hashid, tab: 'property_documents'}
        redirect(error, '/property_documents/index_with_notice', '削除に失敗しました。', params)
      }
    },
    [propertyDocuments]
  )

  return (
    <div className="mt-10 mb-10">
      <LoadingOverlay isOpen={isLoading} text="取り込み中" />
      <div className="border-b-[1px] border-primary mb-2">
        <span className="inline-flex bg-primary text-white text-sm py-2 px-8">帳票作成</span>
      </div>
      <div className="text-right mb-2">
        <AnchorButton
          className="text-primary-font"
          prefix={<AddOutlinedIcon fontSize="small" />}
          outline
          size="small"
          variant="primary"
          href={`/property_documents/new?property_id=${property.hashid}`}
        >
          <span className="hidden md:inline">帳票追加</span>
        </AnchorButton>
      </div>
      <div className="mb-2">
        <Table borderLine={false}>
          <thead className="bg-gray-150 text-sm sticky top-0">
            <tr className="whitespace-nowrap">
              <th
                scope="col"
                className="py-2 px-4 font-medium cursor-pointer"
                onClick={() => {
                  sortType === 'name-asc' ? setSortType('name-desc') : setSortType('name-asc')
                }}
              >
                <div className={'flex'}>
                  <p className={'my-auto'}>帳票名</p>
                  <div className={'w-6'}>
                    {sortType === 'name-asc' && <KeyboardArrowUpIcon />}
                    {sortType === 'name-desc' && <KeyboardArrowDownIcon />}
                  </div>
                </div>
              </th>
              <th
                scope="col"
                className="py-2 px-4 font-medium cursor-pointer"
                onClick={() => {
                  sortType === 'format_name-asc' ? setSortType('format_name-desc') : setSortType('format_name-asc')
                }}
              >
                <div className={'flex'}>
                  <p className={'my-auto'}>物件概要書フォーマット名</p>
                  <div className={'w-6'}>
                    {sortType === 'format_name-asc' && <KeyboardArrowUpIcon />}
                    {sortType === 'format_name-desc' && <KeyboardArrowDownIcon />}
                  </div>
                </div>
              </th>
              <th
                scope="col"
                className="py-2 px-4 font-medium cursor-pointer"
                onClick={() => {
                  sortType === 'date-asc' ? setSortType('date-desc') : setSortType('date-asc')
                }}
              >
                <div className={'flex'}>
                  <p className={'my-auto'}>登録日時</p>
                  <div className={'w-6'}>
                    {sortType === 'date-asc' && <KeyboardArrowUpIcon />}
                    {sortType === 'date-desc' && <KeyboardArrowDownIcon />}
                  </div>
                </div>
              </th>
              <th
                scope="col"
                className="py-2 px-4 font-medium cursor-pointer"
                onClick={() => {
                  sortType === 'user_name-asc' ? setSortType('user_name-desc') : setSortType('user_name-asc')
                }}
              >
                <div className={'flex'}>
                  <p className={'my-auto'}>担当者</p>
                  <div className={'w-6'}>
                    {sortType === 'user_name-asc' && <KeyboardArrowUpIcon />}
                    {sortType === 'user_name-desc' && <KeyboardArrowDownIcon />}
                  </div>
                </div>
              </th>
              <th scope="col" className="py-2 px-4 font-medium"></th>
            </tr>
          </thead>
          <tbody className="whitespace-nowrap">
            {propertyDocuments.map((propertyDocument, index) => (
              <tr key={index} className={`${index % 2 ? 'bg-gray-light' : 'bg-white'}`}>
                <td className="py-2 px-4">
                  <a href={`/property_documents/${propertyDocument.id}/edit?property_id=${property.hashid}`} rel="noreferrer" className="text-primary">
                    {propertyDocument.name}
                  </a>
                </td>
                <td className="py-2 px-4">
                  {propertyDocument.format_name}
                </td>
                <td className="py-2 px-4">
                  {format(parseISO(propertyDocument.created_at), 'yyyy/MM/dd HH:mm:ss')}
                </td>
                <td className="py-2 px-4">{propertyDocument.user_name} / {propertyDocument.department_name}</td>
                <td className="py-2 px-4 text-right">
                  <a
                    className="inline-block w-[30px] hover:text-deleteHover"
                    href={`/property_documents/${propertyDocument.id}/correction?property_id=${property.hashid}`}
                  >
                    <SettingsOutlinedIcon fontSize="small" />
                  </a>
                  <span
                    className="inline-block w-[30px] hover:text-deleteHover cursor-pointer"
                    onClick={() => {
                      pdfDownloadPropertyDocument(propertyDocument.id)
                    }}
                  >
                    <PictureAsPdfIcon fontSize="small" />
                  </span>
                  <span
                    className="inline-block w-[30px] hover:text-deleteHover cursor-pointer"
                    onClick={() => excelDownloadPropertyDocument(propertyDocument.id)}
                  >
                    <TableChartIcon fontSize="small" />
                  </span>
                  <a
                    className="inline-block w-[30px] hover:text-deleteHover"
                    href={`/property_documents/${propertyDocument.id}/combine?property_id=${property.hashid}`}
                  >
                    <AttachmentIcon fontSize="small" />
                  </a>
                  <span
                    className="inline-block w-[30px] hover:text-deleteHover cursor-pointer"
                    onClick={() => {
                      deletePropertyDocument(propertyDocument.id)
                    }}
                  >
                    <DeleteOutlined fontSize="small" />
                  </span>
                </td>
              </tr>
            ))}
          </tbody>
        </Table>
      </div>
    </div>
  )
}

export default PropertyDocuments

import ClearIcon from '@material-ui/icons/Clear'
import * as React from 'react'
import { searchTransportaiton } from '@/utils/searchTransportaiton'
import { Address } from '@/components/Address'
import { Breadcrumb } from '@/components/Breadcrumb'
import { Button } from '@/components/Button'
import { ConfirmDialog } from '@/components/ConfirmDialog'
import { ErrorMessage } from '@/components/ErrorMessage'
import { FileUpload, FileUploadHandle } from '@/components/FileUpload'
import { GoogleMapViewer, MapHandles } from '@/components/GoogleMapViewer/MarketData'
import { Input } from '@/components/Input'
import Base from '@/components/Layout/Base'
import { OCRLayoutMarketData as OCRLayout } from '@/components/Layout/Ocr'
import { RoadAccessRow } from '@/components/Layout/RoadAccessRow'
import { TransportationRow } from '@/components/Layout/TransportationRow'
import { ocrExecution } from '@/components/Ocr'
import { OCRPreviewZone } from '@/components/Ocr/FileUploader'
import { AcceptableMimeTypes } from '@/components/Ocr/file'
import { RadioButton } from '@/components/RadioButton'
import { Select } from '@/components/Select'
import { Row, Table, Td, Th } from '@/components/Table'
import { useJsApiLoader } from '@react-google-maps/api'
import type { User } from '@/types/user'
import { Textarea } from '@/components/Textarea'

import { previousCity } from '@/models/previousCity'

import {
  buildingTypeRentOptions,
  buildingTypeSaleOptions,
  floorTypeOptions,
  orientationOptions,
  radioOptions,
  statusOptions,
} from '@/models/marketDatum'
import {
  LatDefault,
  LngDefault,
  buildingStructureOptions,
  propertyTypeOptions,
} from '@/models/property'
import type { MarketDatum } from '@/types/marketDatum'
import type { LatLng, Transportation } from '@/types/property'
import type { RoadAccess } from '@/types/roadAccess'
import { calculateTsubo, calculateUnitPrice } from '@/utils/area'
import {
  cammedFormat,
  cammedFormats,
  dataPrecisionSetup,
  intFormat,
  formatDecimalToPercentage,
  formatPercentageToDecimal,
} from '@/utils/cammedFormat'
import { detectLargeScreen } from '@/utils/screen'

const defaultTransportationData = {
  bus: 0,
  minutes_on_foot: 0,
  nearest_station: '',
  transportation: '',
  walking: 0,
}
const defaultRoadAccessData = {
  direction: 0,
  load_type: 0,
  width: '',
  frontage: '',
}

type Props = {
  google_maps_api_key: string
  polygons_api_base_url: string
  current_user: User
  authenticity_token?: string
  is_edit: boolean
  market_datum: MarketDatum
  transportations: Transportation[]
  road_accesses: RoadAccess[]
  attached_file_name?: string
  attached_file_base64_text_image?: string
  attached_file_base64_text_pdf?: string
  property_id?: string
  tab_index?: string
  ocr_mode_enabled: boolean
  error_messages: string[]
}

const MarketDataNewEditPage: React.FC<Props> = ({
  google_maps_api_key,
  polygons_api_base_url,
  current_user,
  authenticity_token,
  is_edit,
  market_datum,
  transportations,
  road_accesses,
  attached_file_name,
  attached_file_base64_text_image,
  attached_file_base64_text_pdf,
  property_id,
  tab_index,
  ocr_mode_enabled,
  error_messages,
}) => {
  const { isLoaded } = useJsApiLoader({
    id: 'google-map-script',
    googleMapsApiKey: google_maps_api_key,
    libraries: ['geometry', 'drawing', 'places'],
  })

  const mapRef = React.useRef<MapHandles>(null)
  const fileRef = React.useRef<FileUploadHandle>(null)

  const searchParams = new URLSearchParams(window.location.search)
  const redirect_to: string | undefined = encodeURIComponent(searchParams.get('redirect_to'))

  // PCかスマホ/タブレットの画面かの判定
  const largeScreen = detectLargeScreen()
  const title = is_edit ? '周辺事例編集' : '周辺事例登録'
  const forSale = market_datum.data_type === 'for_sale'
  const forRent = market_datum.data_type === 'for_rent'
  const latLng: LatLng = {
    lat: market_datum.lat || LatDefault,
    lng: market_datum.lng || LngDefault,
  }
  market_datum.floor_type = market_datum.floor_type || 'above_ground'

  const [data, setData] = React.useState({})
  const [imagePreview, setImagePreview] = React.useState<string>(
    attached_file_base64_text_image || ''
  )
  const [pdfPreview, setPdfPreview] = React.useState<string>(attached_file_base64_text_pdf || '')
  const [dialogOpen, setDialogOpen] = React.useState<boolean>(false)
  const [dataLatLng, setDataLatLng] = React.useState<LatLng>({
    lat: market_datum.lat,
    lng: market_datum.lng,
  })
  const [clearAttachedFile, setClearAttachedFile] = React.useState<boolean>(false)
  const [ocr, setOcr] = React.useState<ocrExecution>({
    localFileValidity: true,
  } as unknown as ocrExecution)

  if (ocr_mode_enabled && error_messages.length > 0 && ocr.jobStatus != 'validationError') {
    setOcr((prevState) => {
      return { ...prevState, jobStatus: 'validationError' }
    })
  }
  const [isFinishOcr, setFinishOcr] = React.useState<boolean>(false)
  React.useEffect(() => {
    if (
      (ocr?.jobStatus === undefined && isFinishOcr === false) ||
      (ocr?.jobStatus === 'finish' && isFinishOcr === true)
    ) {
      if (dataLatLng.lat === null || dataLatLng.lng === null) {
        return
      }
      if (ocr?.isSkipSearchTra) {
        setOcr((prevState) => {
          return { ...prevState, isSkipSearchTra: false }
        })
        return
      }
      setData((prevData) => {
        const updatedData = {
          ...prevData,
          transportations: [defaultTransportationData],
        }
        if (!isLoaded) {
          return updatedData
        }
        searchTransportaiton(updatedData, setData, dataLatLng)
        return updatedData
      })
    } else if (ocr?.jobStatus !== undefined || ocr?.jobStatus !== 'finish') {
      setFinishOcr(true)
    } else if (ocr?.jobStatus === 'finish') {
      if (isFinishOcr === true) {
        setFinishOcr(false)
      } else {
        setFinishOcr(true)
      }
    }
  }, [dataLatLng])
  function totalFloorAreaChanged(data: any) {
    const area_data = {}

    const total_floor_area = data.total_floor_area
      ? dataPrecisionSetup(String(data.total_floor_area), 12, 0)
      : 0

    let occupied_area = data.occupied_area
      ? dataPrecisionSetup(String(data.occupied_area), 12, 0)
      : 0

    if (total_floor_area && !occupied_area) {
      occupied_area = total_floor_area
      area_data['occupied_area'] = occupied_area ? cammedFormat(occupied_area, 2) : ''
      area_data['occupied_area_tsubo'] = occupied_area ? calculateTsubo(occupied_area) : ''
    }

    return area_data
  }

  React.useEffect(() => {
    const data = {
      ...market_datum,
      ...cammedFormats(
        market_datum,
        [
          'area_m3',
          'effective_area',
          'total_floor_area',
          'occupied_area',
          'area_m3_tsubo',
          'effective_area_tsubo',
          'total_floor_area_tsubo',
          'occupied_area_tsubo',
        ],
        2
      ),
      ...cammedFormats(
        market_datum,
        [
          'rent',
          'area_m3',
          'effective_area',
          'area_m3_tsubo',
          'effective_area_tsubo',
          'maintenance_fee',
          'rent_maintenance_fee',
          'rent_maintenance_fee_unit_price',
          'rent_maintenance_fee_unit_price_tsubo',
          'security_deposit',
          'key_money',
          'building_price',
          'current_rent',
          'current_noi',
          'full_prospective_rent',
          'full_prospective_noi',
          'sale_price',
          'current_rent',
          'build_age',
        ],
        0
      ),
      city: previousCity.includes(market_datum?.city)
        ? `${market_datum?.city}(再編前)`
        : market_datum?.city,
      sale_prospective_yield: market_datum?.sale_prospective_yield
        ? formatDecimalToPercentage(market_datum?.sale_prospective_yield).toFixed(2)
        : null,
      prospective_yield: market_datum?.prospective_yield
        ? formatDecimalToPercentage(market_datum?.prospective_yield).toFixed(2)
        : null,
      sale_prospective_noi_yield: market_datum?.sale_prospective_noi_yield
        ? formatDecimalToPercentage(market_datum?.sale_prospective_noi_yield).toFixed(2)
        : null,
      contract_prospective_noi_yield: market_datum?.contract_prospective_noi_yield
        ? formatDecimalToPercentage(market_datum?.contract_prospective_noi_yield).toFixed(2)
        : null,
      build_age: calculateBuildAge(market_datum),
      transportations: transportations.length > 0 ? transportations : [defaultTransportationData],
      road_accesses: road_accesses.length > 0 ? road_accesses : [defaultRoadAccessData],
    }
    setData(data)
  }, [])

  // エラーの時も地図の初期表示を引き継ぐ
  React.useEffect(() => {
    if (
      mapRef?.current &&
      (market_datum.prefecture !== '東京都' ||
        market_datum.city ||
        market_datum.town ||
        market_datum.chome)
    ) {
      mapRef.current.setLocation(
        location({
          prefecture: market_datum.prefecture,
          city: market_datum.city,
          town: market_datum.town,
          chome: market_datum.chome,
        })
      )
    } else if (
      mapRef?.current &&
      (market_datum.prefecture !== '東京都' || data.city || data.town || data.chome)
    ) {
      mapRef.current.setLocation(
        location({
          prefecture: data.prefecture,
          city: data.city,
          town: data.town,
          chome: data.chome,
        })
      )
    }
  }, [isLoaded, ocr.jobStatus])

  // convert base64 encoded attached file into File object
  const fileData = pdfPreview || imagePreview
  React.useEffect(() => {
    fetch(fileData)
      .then((res) => res.blob())
      .then((blob) => {
        setOcr((prevState) => {
          return {
            ...prevState,
            localFile: new File([blob], attached_file_name, { type: 'application/pdf' }),
          }
        })
      })
  }, [fileData])

  const calculateRentMaintenanceFee = (rent, maintenanceFee): string => {
    const rentVelue = rent ? dataPrecisionSetup(rent, 12, 0) : 0
    const maintenanceFeeVelue = maintenanceFee ? dataPrecisionSetup(maintenanceFee, 12, 0) : 0
    const rentMaintenanceFee = rentVelue + maintenanceFeeVelue

    return rentMaintenanceFee !== 0 ? cammedFormat(String(rentMaintenanceFee), 0) : ''
  }

  const calculateBuildAge = (data) => {
    if (!data.build_date) return null

    const currentDate = new Date().toISOString().split('T')[0]
    let referenceDate

    if (data.status === 'open' && data.publication_date) {
      referenceDate = data.publication_date
    } else if (data.status === 'close' && data.contract_date) {
      referenceDate = data.contract_date
    } else {
      referenceDate = data.created_at || currentDate
    }

    const buildDateTime = new Date(data.build_date)
    const compareDateTime = new Date(referenceDate)

    if (isNaN(buildDateTime.getTime())) {
      return null
    }
    let monthDiff =
      compareDateTime.getFullYear() * 12 +
      compareDateTime.getMonth() -
      (buildDateTime.getFullYear() * 12 + buildDateTime.getMonth())

    if (compareDateTime.getDate() > buildDateTime.getDate()) {
      monthDiff += 1
    }

    if (monthDiff < 0) {
      return 0
    }

    const years = Math.floor(monthDiff / 12)
    const months = monthDiff % 12

    if (months === 0) {
      return `${years}年`
    } else {
      return `${years}年${months}ヶ月`
    }
  }

  const placeHolderText = {
    spaceMetrics: {
      土地: '計画建物の面積を記載してください',
      '土地＋建物（一棟）': '既存建物の面積を記載してください',
      '土地＋建物（区分）': '既存建物の面積を記載してください',
    },
    location_of_division: {
      土地: '',
      '土地＋建物（一棟）': '',
      '土地＋建物（区分）': '既存建物を記載してください',
    },
  }

  const getPlaceholderText = (propertyType, type = 'spaceMetrics') => {
    return placeHolderText[type]?.[propertyType] || ''
  }

  const onCloseDialog = (result: boolean) => {
    setDialogOpen(false)

    if (result) {
      const redirectTo = searchParams.get('redirect_to')
      if (redirectTo) {
        window.location.href = redirectTo
      } else {
        window.location.href = is_edit
          ? `/properties/${property_id}?tab=surroundings&tab_index=${tab_index}`
          : '/'
      }
    }
  }

  const onDrop = (file: File) => {
    const reader = new FileReader()

    reader.onload = (e) => {
      const base64Text = e.currentTarget.result

      if (file.type === 'application/pdf') {
        // PDFファイルのプレビュー
        setImagePreview('')
        setPdfPreview(base64Text)
      } else {
        // 画像ファイルのプレビュー
        setPdfPreview('')
        setImagePreview(base64Text)
      }

      if (is_edit) {
        // 更新時のクリアフラグを解除
        setClearAttachedFile(false)
      }
    }
    reader.readAsDataURL(file)
    // OCR
    if (!is_edit && ocr.jobStatus != 'finish' && ocr.jobStatus != 'validationError') {
      setOcr((prevState) => {
        return {
          ...prevState,
          jobStatus: 'preview',
          localFile: file,
        }
      })
    }
  }

  const onClear = () => {
    // ファイルとファイル名をクリア
    fileRef.current.clearFile()

    // プレビューを削除
    setImagePreview('')
    setPdfPreview('')

    if (is_edit && attached_file_name) {
      // ファイルが添付されていた場合は更新時のクリアフラグを設定
      setClearAttachedFile(true)
    }
    setOcr((prevState) => {
      return { ...prevState, jobStatus: undefined }
    })
  }

  const mapViewer = (
    <GoogleMapViewer
      polygons_api_base_url={polygons_api_base_url}
      current_user={current_user}
      csrfToken={authenticity_token}
      mode="market_datum"
      property_id={Number(data.id ?? '0')}
      lat={latLng.lat}
      lng={latLng.lng}
      propertyLat={dataLatLng.lat}
      propertyLng={dataLatLng.lng}
      onChangeLatLng={(latLng: LatLng) => {
        setDataLatLng(latLng)
      }}
      ref={mapRef}
      style={mapStyle}
    />
  )

  const fileUploader = (
    <>
      <FileUpload
        className={'mt-4 w-full'}
        hidden={ocr.jobStatus == 'preview'}
        name="market_datum[attached_file]"
        accept={AcceptableMimeTypes}
        onDrop={onDrop}
        filenameInitial={attached_file_name || ocr.localFile?.name}
        ref={fileRef}
      />
      {imagePreview && (
        <div className="relative">
          <ClearIcon
            className="absolute right-0 top-[-10px] bg-white rounded-full cursor-pointer"
            onClick={onClear}
          />
          <embed
            className="w-full mt-4"
            alt="画像プレビュー"
            src={imagePreview}
            style={{ width: '100%', height: 'auto' }}
          />
        </div>
      )}
      {pdfPreview && (
        <div className="relative mt-3 w-full h-[80vh]">
          <ClearIcon
            className="absolute right-0 top-[-10px] bg-white rounded-full cursor-pointer"
            onClick={onClear}
          />
          <embed
            className="w-full h-full mt-4 border-b border-b-[3px] border-gray-700"
            alt="PDFプレビュー"
            src={pdfPreview + '#view=Fit'}
            style={{ width: '100%', height: '95%' }}
          />
        </div>
      )}
    </>
  )

  return (
    <Base
      current_user={current_user}
      title={title}
      breadcrumb={<Breadcrumb link_texts={[title]} />}
    >
      <form
        action={
          is_edit
            ? `/market_data/${data.hashid}?data_type=${market_datum.data_type}&property_id=${property_id}&tab_index=${tab_index}&redirect_to=${redirect_to}`
            : `/market_data?data_type=${market_datum.data_type}&redirect_to=${redirect_to}`
        }
        method="post"
        encType="multipart/form-data"
      >
        {redirect_to && <input type="hidden" name="redirect_to" value={redirect_to} />}
        <input type="hidden" name="_method" value={is_edit ? 'patch' : 'post'} />
        <input type="hidden" name="authenticity_token" value={authenticity_token} />
        <input type="hidden" name="data_type" value={market_datum.data_type} />
        <input type="hidden" name="market_datum[lat]" value={dataLatLng.lat} />
        <input type="hidden" name="market_datum[lng]" value={dataLatLng.lng} />
        {is_edit && (
          <>
            <input type="hidden" name="tab_index" value={tab_index} />
            <input type="hidden" name="clear_attached_file" value={clearAttachedFile} />
          </>
        )}
        <OCRLayout
          isEdit={is_edit}
          ocrStatus={ocr.jobStatus}
          isLoaded={isLoaded}
          largeScreen={largeScreen}
          mapViewer={mapViewer}
          previewZone={
            <OCRPreviewZone
              isEdit={is_edit}
              ocr={ocr}
              setOcr={setOcr}
              formatType={forSale ? 'purchase' : 'rent'}
              fileRef={fileRef}
              setProperty={setData}
              csrfToken={authenticity_token}
            />
          }
        >
          <ErrorMessage className="mt-6" error_messages={filterErrorMessages(error_messages)} />

          <div className="pb-20 lg:flex gap-4">
            <div className="w-full">
              <div
                onKeyDown={(e) => {
                  const element = e.target as HTMLElement
                  // 備考欄で改行できなくなるため、TEXTAREA要素はエンターをpreventDefaultしない
                  if (e.key === 'Enter' && element.tagName !== 'TEXTAREA') {
                    e.preventDefault()
                  }
                }}
              >
                <Table
                  className="border-t border-b block mb-16 overflow-x-visible"
                  header={
                    <div className="flex justify-between items-center bg-primary text-white py-2 px-4 text-sm">
                      基本情報
                    </div>
                  }
                >
                  <tbody className="block md:table-row-group">
                    <Row label={<Th>事例種別</Th>}>
                      <Td>
                        <div className="md:w-[300px]">{data.data_type_text}</div>
                      </Td>
                    </Row>
                    <Row label={<Th required>状態</Th>}>
                      <Td>
                        <div className="flex gap-8">
                          {statusOptions.map((item, i) => (
                            <RadioButton
                              key={i}
                              name="market_datum[status]"
                              id={`market_datum_status_${i}`}
                              text={item.text}
                              value={item.value}
                              checked={item.value === data.status}
                              onChange={(e) =>
                                setData({
                                  ...data,
                                  status: e.target.value,
                                })
                              }
                            />
                          ))}
                        </div>
                      </Td>
                    </Row>
                    <Address
                      mapRef={mapRef}
                      current_user={current_user}
                      prefectureInitial={data?.prefecture || market_datum.prefecture}
                      cityInitial={data?.city || market_datum.city}
                      townInitial={data?.town || market_datum.town}
                      name={'market_datum'}
                      ocrExtractedItems={ocr && ocr.extractedItems}
                      data={data}
                      onChangeData={setData}
                      error_messages={error_messages}
                      is_edit={is_edit}
                    />
                    <Row label={<Th required>物件種目</Th>}>
                      <Td>
                        <div className="flex gap-8">
                          {propertyTypeOptions.map((item, i) => (
                            <RadioButton
                              key={i}
                              name="market_datum[property_type]"
                              id={`market_datum_property_type_${i}`}
                              text={item.text}
                              value={item.value}
                              checked={item.text === data.property_type}
                              onChange={(e) => {
                                let totalFloorAreaChangedData = {}
                                if (!is_edit && e.target.value !== '土地') {
                                  totalFloorAreaChangedData = {
                                    ...totalFloorAreaChanged(data),
                                  }
                                }
                                setData({
                                  ...data,
                                  ...totalFloorAreaChangedData,
                                  property_type: e.target.value,
                                  location_of_division:
                                    e.target.value === '土地＋建物（区分）'
                                      ? data.location_of_division
                                      : '',
                                })
                              }}
                            />
                          ))}
                        </div>
                      </Td>
                    </Row>
                    <Row label={<Th>物件タイプ</Th>}>
                      <Td>
                        <div className="py-1 flex gap-4 flex-col md:flex-row">
                          <Select
                            className="md:w-[300px]"
                            name="market_datum[building_type]"
                            id="market_datum_building_type"
                            value={data.building_type || ''}
                            hasBlank
                            blankLabel="(未設定)"
                            options={forSale ? buildingTypeSaleOptions : buildingTypeRentOptions}
                            onChange={(e) => {
                              setData({
                                ...data,
                                building_type: e.target.value,
                              })
                              if (e.target.value !== 'others_type') {
                                data.custom_building_type = null
                              }
                            }}
                          />
                          {data.building_type === 'others_type' && (
                            <Input
                              className="md:w-[300px]"
                              prefix="詳細"
                              value={data.custom_building_type || ''}
                              name="market_datum[custom_building_type]"
                              id="market_datum_custom_building_type"
                              placeholder=""
                              onChange={(e) =>
                                setData({
                                  ...data,
                                  custom_building_type: e.target.value,
                                })
                              }
                            />
                          )}
                        </div>
                      </Td>
                    </Row>
                  </tbody>
                </Table>
                {/* スマホ・タブレット */}
                <div className="lg:hidden my-4">
                  {isLoaded && !largeScreen && (
                    <GoogleMapViewer
                      polygons_api_base_url={polygons_api_base_url}
                      current_user={current_user}
                      csrfToken={authenticity_token}
                      mode="market_datum"
                      property_id={Number(data.id ?? '0')}
                      lat={latLng.lat}
                      lng={latLng.lng}
                      propertyLat={dataLatLng.lat}
                      propertyLng={dataLatLng.lng}
                      onChangeLatLng={(latLng: LatLng) => {
                        setDataLatLng(latLng)
                      }}
                      ref={mapRef}
                      style={mapStyle}
                    />
                  )}
                  {!largeScreen && (
                    <>
                      <FileUpload
                        className="mt-4"
                        name="market_datum[attached_file]"
                        accept={AcceptableMimeTypes}
                        onDrop={onDrop}
                        filenameInitial={attached_file_name}
                        ref={fileRef}
                        setOcr={setOcr}
                      />
                      {imagePreview && (
                        <div className="relative">
                          <ClearIcon
                            className="absolute right-0 top-[-10px] bg-white rounded-full cursor-pointer"
                            onClick={onClear}
                          />
                          <embed
                            className="mt-4"
                            alt="画像プレビュー"
                            src={imagePreview}
                            style={{ width: '100%', height: 'auto' }}
                          />
                        </div>
                      )}
                      {/* スマホなどの画面ではPDFが表示されないためプレビューはなし */}
                      {pdfPreview && (
                        <div className="relative">
                          <ClearIcon
                            className="absolute right-0 top-[-10px] bg-white rounded-full cursor-pointer"
                            onClick={onClear}
                          />
                          <p className="p-2 text-xs">
                            ※スマートフォンやタブレットPCの場合はPDFファイルのプレビューは表示されません
                          </p>
                        </div>
                      )}
                    </>
                  )}
                </div>
                <Table
                  className="border-t border-b block mb-16"
                  header={
                    <div className="flex justify-between items-center bg-primary text-white py-2 px-4 text-sm">
                      事例情報
                    </div>
                  }
                >
                  <tbody className="block md:table-row-group">
                    {data.transportations?.map((transportation, index) => (
                      <TransportationRow
                        index={index}
                        transportation={transportation}
                        data={data}
                        setData={setData}
                        dataLatLng={dataLatLng}
                        defaultTransportationData={defaultTransportationData}
                        requestParamName="transportations"
                      />
                    ))}
                    {data.road_accesses?.map((road_access, index) => (
                      <RoadAccessRow
                        index={index}
                        road_access={road_access}
                        data={data}
                        setData={setData}
                        dataLatLng={dataLatLng}
                        defaultRoadAccessData={defaultRoadAccessData}
                        requestParamName="road_accesses"
                      />
                    ))}
                    <Row label={<Th>掲載日</Th>}>
                      <Td>
                        <Input
                          className="md:w-[300px]"
                          value={data.publication_date || ''}
                          name="market_datum[publication_date]"
                          id="market_datum_publication_date"
                          type="date"
                          onChange={(e) =>
                            setData({
                              ...data,
                              publication_date: e.target.value,
                            })
                          }
                          onBlur={(e) => {
                            const buildAge = calculateBuildAge({
                              ...data,
                              publication_date: e.target.value,
                            })
                            setData({
                              ...data,
                              publication_date: e.target.value,
                              build_age: buildAge,
                            })
                          }}
                          maxCalendarYear={3}
                        />
                      </Td>
                    </Row>
                    <Row label={<Th>成約日</Th>}>
                      <Td>
                        <Input
                          className="md:w-[300px]"
                          value={data.contract_date || ''}
                          name="market_datum[contract_date]"
                          id="market_datum_contract_date"
                          type="date"
                          onChange={(e) =>
                            setData({
                              ...data,
                              contract_date: e.target.value,
                            })
                          }
                          onBlur={(e) => {
                            const buildAge = calculateBuildAge({
                              ...data,
                              contract_date: e.target.value,
                            })
                            setData({
                              ...data,
                              contract_date: e.target.value,
                              build_age: buildAge,
                            })
                          }}
                          maxCalendarYear={3}
                        />
                      </Td>
                    </Row>
                    <Row label={<Th>物件名</Th>}>
                      <Td>
                        <Input
                          className="md:w-[300px]"
                          value={data.name || ''}
                          name="market_datum[name]"
                          id="market_datum_name"
                          onChange={(e) =>
                            setData({
                              ...data,
                              name: e.target.value,
                            })
                          }
                        />
                      </Td>
                    </Row>
                    <Row label={<Th required={data?.property_type === '土地'}>敷地面積</Th>}>
                      <Td>
                        <div className="flex gap-4 flex-col md:flex-row">
                          <Input
                            className="md:w-[300px]"
                            value={data.area_m3 || ''}
                            suffix="㎡"
                            placeholder=""
                            onChange={(e) =>
                              setData({
                                ...data,
                                area_m3: e.target.value,
                              })
                            }
                            onBlur={(e) => {
                              const value = e.target.value
                                ? dataPrecisionSetup(e.target.value, 12, 2)
                                : ''
                              const area_m3 = value ? cammedFormat(value, 2) : ''
                              const tsubo = value ? calculateTsubo(value) : ''
                              setData({
                                ...data,
                                area_m3,
                                area_m3_tsubo: tsubo,
                              })
                            }}
                          />
                          <input
                            type="hidden"
                            value={data.area_m3 ? intFormat(String(data.area_m3)) : ''}
                            name="market_datum[area_m3]"
                            id="market_datum_area_m3"
                          />
                          <Input
                            className="md:w-[300px] w-full"
                            value={data.area_m3_tsubo || ''}
                            suffix="坪"
                            placeholder=""
                            readOnly
                            tabIndex={-1}
                          />
                        </div>
                      </Td>
                    </Row>
                    <Row label={<Th>有効敷地面積</Th>}>
                      <Td>
                        <div className="flex gap-4 flex-col md:flex-row">
                          <Input
                            className="md:w-[300px]"
                            value={data.effective_area || ''}
                            suffix="㎡"
                            placeholder=""
                            onChange={(e) =>
                              setData({
                                ...data,
                                effective_area: e.target.value,
                              })
                            }
                            onBlur={(e) => {
                              const value = e.target.value
                                ? dataPrecisionSetup(e.target.value, 12, 2)
                                : ''
                              const effective_area = value ? cammedFormat(value, 2) : ''
                              const tsubo = value ? calculateTsubo(value) : ''
                              setData({
                                ...data,
                                effective_area,
                                effective_area_tsubo: tsubo,
                              })
                            }}
                          />
                          <input
                            type="hidden"
                            value={
                              data.effective_area ? intFormat(String(data.effective_area)) : ''
                            }
                            name="market_datum[effective_area]"
                            id="market_datum_effective_area"
                          />
                          <Input
                            className="md:w-[300px] w-full"
                            value={data.effective_area_tsubo || ''}
                            suffix="坪"
                            placeholder=""
                            readOnly
                            tabIndex={-1}
                          />
                        </div>
                      </Td>
                    </Row>
                    <>
                      <Row label={<Th>延床面積</Th>}>
                        <Td>
                          <div className="flex gap-4 flex-col md:flex-row">
                            <Input
                              className="md:w-[300px]"
                              value={data.total_floor_area || ''}
                              suffix="㎡"
                              placeholder=""
                              onChange={(e) =>
                                setData({
                                  ...data,
                                  total_floor_area: e.target.value,
                                })
                              }
                              onBlur={(e) => {
                                const value = e.target.value
                                  ? dataPrecisionSetup(e.target.value, 12, 2)
                                  : ''
                                const total_floor_area = value ? cammedFormat(value, 2) : ''
                                const tsubo = value ? calculateTsubo(value) : ''
                                setData({
                                  ...data,
                                  total_floor_area,
                                  total_floor_area_tsubo: tsubo,
                                })
                              }}
                            />
                            <input
                              type="hidden"
                              value={
                                data.total_floor_area
                                  ? intFormat(String(data.total_floor_area))
                                  : ''
                              }
                              name="market_datum[total_floor_area]"
                              id="market_datum_total_floor_area"
                            />
                            <Input
                              className="md:w-[300px] w-full"
                              value={data.total_floor_area_tsubo || ''}
                              suffix="坪"
                              placeholder=""
                              readOnly
                              tabIndex={-1}
                            />
                          </div>
                        </Td>
                      </Row>
                      <Row label={<Th required={data.property_type !== '土地'}>専有面積</Th>}>
                        <Td>
                          <div className="flex gap-4 flex-col md:flex-row">
                            <Input
                              className="md:w-[300px]"
                              value={data.occupied_area || ''}
                              suffix="㎡"
                              placeholder={getPlaceholderText(data?.property_type, 'spaceMetrics')}
                              onChange={(e) =>
                                setData({
                                  ...data,
                                  occupied_area: e.target.value,
                                })
                              }
                              onBlur={(e) => {
                                const value = e.target.value
                                  ? dataPrecisionSetup(e.target.value, 12, 2)
                                  : ''
                                const occupied_area = value ? cammedFormat(value, 2) : ''
                                const tsubo = value ? calculateTsubo(value) : ''
                                setData({
                                  ...data,
                                  occupied_area,
                                  occupied_area_tsubo: tsubo,
                                })
                              }}
                            />
                            <input
                              type="hidden"
                              value={
                                data.occupied_area ? intFormat(String(data.occupied_area)) : ''
                              }
                              name="market_datum[occupied_area]"
                              id="market_datum_occupied_area"
                            />
                            <Input
                              className="md:w-[300px] w-full"
                              value={data.occupied_area_tsubo || ''}
                              suffix="坪"
                              placeholder=""
                              readOnly
                              tabIndex={-1}
                            />
                          </div>
                        </Td>
                      </Row>
                    </>
                    <Row label={<Th>建物階数</Th>}>
                      <Td>
                        <div className="flex gap-4 flex-col md:flex-row">
                          <Input
                            className="md:w-[300px]"
                            value={data.floors_above_ground || ''}
                            prefix="地上"
                            suffix="階"
                            onChange={(e) =>
                              setData({
                                ...data,
                                floors_above_ground: e.target.value,
                              })
                            }
                            onBlur={(e) => {
                              const value = e.target.value
                                ? cammedFormat(dataPrecisionSetup(e.target.value, 10, 0), 0)
                                : ''
                              setData({
                                ...data,
                                floors_above_ground: value,
                              })
                            }}
                          />
                          <input
                            type="hidden"
                            name="market_datum[floors_above_ground]"
                            id="market_datum_floors_above_ground"
                            value={
                              data.floors_above_ground
                                ? intFormat(String(data.floors_above_ground))
                                : ''
                            }
                          />
                          <Input
                            className="md:w-[300px]"
                            value={data.floors_under_ground || ''}
                            prefix="地下"
                            suffix="階"
                            onChange={(e) =>
                              setData({
                                ...data,
                                floors_under_ground: e.target.value,
                              })
                            }
                            onBlur={(e) => {
                              const value = e.target.value
                                ? cammedFormat(dataPrecisionSetup(e.target.value, 10, 0), 0)
                                : ''
                              setData({
                                ...data,
                                floors_under_ground: value,
                              })
                            }}
                          />
                          <input
                            type="hidden"
                            name="market_datum[floors_under_ground]"
                            id="market_datum_floors_under_ground"
                            value={
                              data.floors_under_ground
                                ? intFormat(String(data.floors_under_ground))
                                : ''
                            }
                          />
                        </div>
                      </Td>
                    </Row>
                    {forSale && (
                      <Row label={<Th>総戸数</Th>}>
                        <Td>
                          <Input
                            className="md:w-[300px]"
                            value={data.number_of_units || ''}
                            suffix="戸"
                            placeholder=""
                            onChange={(e) =>
                              setData({
                                ...data,
                                number_of_units: e.target.value,
                              })
                            }
                            onBlur={(e) => {
                              const value = e.target.value
                                ? cammedFormat(dataPrecisionSetup(e.target.value, 10, 0), 0)
                                : ''
                              setData({
                                ...data,
                                number_of_units: value,
                              })
                            }}
                          />
                          <input
                            type="hidden"
                            name="market_datum[number_of_units]"
                            id="market_datum_number_of_units"
                            value={
                              data.number_of_units ? intFormat(String(data.number_of_units)) : ''
                            }
                          />
                        </Td>
                      </Row>
                    )}
                    <Row label={<Th>所在階</Th>}>
                      <Td>
                        <div className="flex gap-4 flex-col md:flex-row">
                          {floorTypeOptions.map((item, i) => (
                            <RadioButton
                              key={i}
                              name="market_datum[floor_type]"
                              id={`market_datum_floor_type_${i}`}
                              text={item.text}
                              value={item.value}
                              checked={item.value === data.floor_type}
                              onChange={(e) => {
                                setData({
                                  ...data,
                                  floor_type: e.target.value,
                                })
                              }}
                            />
                          ))}
                        </div>
                        <div className="pt-2 flex gap-4 flex-col md:flex-row">
                          <Input
                            className={`md:w-[300px] bg-white`}
                            value={data.floor || ''}
                            name="market_datum[floor]"
                            id="market_datum_floor"
                            suffix="階"
                            onChange={(e) =>
                              setData({
                                ...data,
                                floor: e.target.value,
                              })
                            }
                            onBlur={(e) => {
                              const value = e.target.value
                                ? cammedFormat(dataPrecisionSetup(e.target.value, 10, 0), 0)
                                : ''
                              setData({
                                ...data,
                                floor: value,
                              })
                            }}
                          />
                          <input
                            type="hidden"
                            name="market_datum[floor]"
                            id="market_datum_floor"
                            value={data.floor ? intFormat(String(data.floor)) : ''}
                          />
                        </div>
                      </Td>
                    </Row>
                    {market_datum.data_type === 'for_rent' && (
                      <Row label={<Th>部屋番号</Th>}>
                        <Td>
                          <Input
                            className="md:w-[300px]"
                            value={data.location_of_division || ''}
                            name="market_datum[location_of_division]"
                            id="market_datum_location_of_division"
                            readOnly={data.property_type !== '土地＋建物（区分）'}
                            tabIndex={data.property_type !== '土地＋建物（区分）' && -1}
                            onChange={(e) =>
                              setData({
                                ...data,
                                location_of_division: e.target.value,
                              })
                            }
                          />
                        </Td>
                      </Row>
                    )}
                    <Row label={<Th>間取り</Th>}>
                      <Td>
                        <Input
                          className="md:w-[300px]"
                          value={data.floor_plan || ''}
                          name="market_datum[floor_plan]"
                          id="market_datum_floor_plan"
                          placeholder=""
                          onChange={(e) =>
                            setData({
                              ...data,
                              floor_plan: e.target.value,
                            })
                          }
                        />
                      </Td>
                    </Row>
                    <Row label={<Th>構造</Th>}>
                      <Td>
                        <Select
                          className="md:w-[300px]"
                          name="market_datum[building_structure]"
                          id="market_datum_building_structure"
                          value={data.building_structure || ''}
                          options={buildingStructureOptions}
                          hasBlank
                          blankLabel="(未設定)"
                          onChange={(e) =>
                            setData({
                              ...data,
                              building_structure: e.target.value,
                            })
                          }
                        />
                      </Td>
                    </Row>
                    <Row label={<Th>竣工年月</Th>}>
                      <Td>
                        <div className="flex gap-4 flex-col md:flex-row">
                          <Input
                            className="md:w-[300px]"
                            value={data.build_date || ''}
                            name="market_datum[build_date]"
                            id="market_datum_build_date"
                            placeholder=""
                            type="date"
                            onChange={(e) =>
                              setData({
                                ...data,
                                build_date: e.target.value,
                              })
                            }
                            onBlur={(e) => {
                              const buildAge = calculateBuildAge({
                                ...data,
                                build_date: e.target.value,
                              })
                              setData({
                                ...data,
                                build_date: e.target.value,
                                build_age: buildAge,
                              })
                            }}
                          />
                          <input
                            type="hidden"
                            value={data.build_age ? intFormat(String(data.build_age)) : ''}
                            name="market_datum[build_age]"
                            id="market_datum_build_age"
                          />
                          <Input
                            className="md:w-[300px] w-full"
                            value={
                              data.build_age === undefined || data.build_age === null
                                ? ''
                                : typeof data.build_age === 'string'
                                ? data.build_age
                                : data.build_age < 12
                                ? `${data.build_age}ヶ月`
                                : `${Math.floor(data.build_age / 12)}年${data.build_age % 12}ヶ月`
                            }
                            prefix="築"
                            placeholder=""
                            readOnly
                            tabIndex={-1}
                          />
                        </div>
                      </Td>
                    </Row>
                    <Row label={<Th>施工会社</Th>}>
                      <Td>
                        <Input
                          className="md:w-[300px]"
                          value={data.contractor || ''}
                          name="market_datum[contractor]"
                          id="market_datum_contractor"
                          onChange={(e) =>
                            setData({
                              ...data,
                              contractor: e.target.value,
                            })
                          }
                        />
                      </Td>
                    </Row>
                    {forRent && (
                      <>
                        <Row label={<Th>賃料</Th>}>
                          <Td>
                            <Input
                              className="md:w-[300px]"
                              value={data.rent || ''}
                              suffix="円"
                              placeholder=""
                              onChange={(e) =>
                                setData({
                                  ...data,
                                  rent: e.target.value,
                                })
                              }
                              onBlur={(e) => {
                                const value = e.target.value
                                  ? dataPrecisionSetup(e.target.value, 12, 0)
                                  : ''
                                const rent = value ? cammedFormat(value, 0) : ''
                                const rentMaintenanceFee = calculateRentMaintenanceFee(
                                  e.target.value,
                                  data.maintenance_fee
                                )
                                const rentMaintenanceFeeUnitPrice = calculateUnitPrice(
                                  rentMaintenanceFee,
                                  data.occupied_area
                                )
                                const rentMaintenanceFeeUnitPriceTsubo = calculateUnitPrice(
                                  rentMaintenanceFee,
                                  data.occupied_area_tsubo
                                )
                                setData({
                                  ...data,
                                  rent,
                                  rent_maintenance_fee: rentMaintenanceFee,
                                  rent_maintenance_fee_unit_price: rentMaintenanceFeeUnitPrice,
                                  rent_maintenance_fee_unit_price_tsubo:
                                    rentMaintenanceFeeUnitPriceTsubo,
                                })
                              }}
                            />
                            <input
                              type="hidden"
                              name="market_datum[rent]"
                              id="market_datum_rent"
                              value={data.rent ? intFormat(String(data.rent)) : ''}
                            />
                          </Td>
                        </Row>
                        <Row label={<Th>管理費</Th>}>
                          <Td>
                            <Input
                              className="md:w-[300px]"
                              value={data.maintenance_fee || ''}
                              suffix="円"
                              placeholder=""
                              onChange={(e) =>
                                setData({
                                  ...data,
                                  maintenance_fee: e.target.value,
                                })
                              }
                              onBlur={(e) => {
                                const value = e.target.value
                                  ? dataPrecisionSetup(e.target.value, 12, 0)
                                  : ''
                                const maintenance_fee = value ? cammedFormat(value, 0) : ''
                                const rentMaintenanceFee = calculateRentMaintenanceFee(
                                  data.rent,
                                  e.target.value
                                )
                                const rentMaintenanceFeeUnitPrice = calculateUnitPrice(
                                  rentMaintenanceFee,
                                  data.occupied_area
                                )
                                const rentMaintenanceFeeUnitPriceTsubo = calculateUnitPrice(
                                  rentMaintenanceFee,
                                  data.occupied_area_tsubo
                                )
                                setData({
                                  ...data,
                                  maintenance_fee,
                                  rent_maintenance_fee: rentMaintenanceFee,
                                  rent_maintenance_fee_unit_price: rentMaintenanceFeeUnitPrice,
                                  rent_maintenance_fee_unit_price_tsubo:
                                    rentMaintenanceFeeUnitPriceTsubo,
                                })
                              }}
                            />
                            <input
                              type="hidden"
                              name="market_datum[maintenance_fee]"
                              id="market_datum_maintenance_fee"
                              value={
                                data.maintenance_fee ? intFormat(String(data.maintenance_fee)) : ''
                              }
                            />
                          </Td>
                        </Row>
                        <Row label={<Th>賃料（管理費込み）</Th>}>
                          <Td>
                            <div className="flex gap-4 flex-col md:flex-row">
                              <Input
                                className="md:w-[300px]"
                                value={data.rent_maintenance_fee || ''}
                                suffix="円"
                                placeholder=""
                                readOnly
                                tabIndex={-1}
                              />
                              <Input
                                className="md:w-[300px]"
                                value={data.rent_maintenance_fee_unit_price || ''}
                                prefix="㎡単価"
                                suffix="円"
                                placeholder=""
                                readOnly
                                tabIndex={-1}
                              />
                            </div>
                          </Td>
                        </Row>
                        <Row label={<Th className="hidden md:block"></Th>}>
                          <Td>
                            <div className="flex gap-4 flex-col md:flex-row">
                              <div className="hidden md:block md:w-[300px]"></div>
                              <Input
                                className="md:w-[300px]"
                                value={data.rent_maintenance_fee_unit_price_tsubo || ''}
                                prefix="坪単価"
                                suffix="円"
                                placeholder=""
                                readOnly
                                tabIndex={-1}
                              />
                            </div>
                          </Td>
                        </Row>
                        <Row label={<Th>敷金</Th>}>
                          <Td>
                            <Input
                              className="md:w-[300px]"
                              value={data.security_deposit || ''}
                              suffix="円"
                              placeholder=""
                              onChange={(e) =>
                                setData({
                                  ...data,
                                  security_deposit: e.target.value,
                                })
                              }
                              onBlur={(e) => {
                                const value = e.target.value
                                  ? dataPrecisionSetup(e.target.value, 12, 0)
                                  : ''
                                const security_deposit = value ? cammedFormat(value, 0) : ''
                                setData({
                                  ...data,
                                  security_deposit,
                                })
                              }}
                            />
                            <input
                              type="hidden"
                              name="market_datum[security_deposit]"
                              id="market_datum_security_deposit"
                              value={
                                data.security_deposit
                                  ? intFormat(String(data.security_deposit))
                                  : ''
                              }
                            />
                          </Td>
                        </Row>
                        <Row label={<Th>礼金</Th>}>
                          <Td>
                            <Input
                              className="md:w-[300px]"
                              value={data.key_money || ''}
                              suffix="円"
                              placeholder=""
                              onChange={(e) =>
                                setData({
                                  ...data,
                                  key_money: e.target.value,
                                })
                              }
                              onBlur={(e) => {
                                const value = e.target.value
                                  ? dataPrecisionSetup(e.target.value, 12, 0)
                                  : ''
                                const key_money = value ? cammedFormat(value, 0) : ''
                                setData({
                                  ...data,
                                  key_money,
                                })
                              }}
                            />
                            <input
                              type="hidden"
                              name="market_datum[key_money]"
                              id="market_datum_key_money"
                              value={data.key_money ? intFormat(String(data.key_money)) : ''}
                            />
                          </Td>
                        </Row>
                        <Row label={<Th>開口向き</Th>}>
                          <Td>
                            <Select
                              className="md:w-[300px]"
                              name="market_datum[orientation]"
                              id="market_datum_orientation"
                              value={data.orientation || ''}
                              options={orientationOptions}
                              hasBlank
                              blankLabel="(未設定)"
                              onChange={(e) =>
                                setData({
                                  ...data,
                                  orientation: e.target.value,
                                })
                              }
                            />
                          </Td>
                        </Row>
                        <Row label={<Th>部屋タイプ</Th>}>
                          <Td>
                            <Input
                              className="md:w-[300px]"
                              value={data.room_type || ''}
                              name="market_datum[room_type]"
                              id="market_datum_room_type"
                              placeholder=""
                              onChange={(e) =>
                                setData({
                                  ...data,
                                  room_type: e.target.value,
                                })
                              }
                            />
                          </Td>
                        </Row>
                      </>
                    )}
                    <Row label={<Th>エレベーター</Th>}>
                      <Td>
                        <div className="flex gap-8">
                          {radioOptions.map((item, i) => (
                            <RadioButton
                              key={i}
                              name="market_datum[elevator]"
                              id={`market_datum_elevator_${i}`}
                              text={item.text}
                              value={item.value}
                              checked={item.value === data.elevator}
                              onChange={(e) =>
                                setData({
                                  ...data,
                                  elevator: e.target.value,
                                })
                              }
                            />
                          ))}
                        </div>
                      </Td>
                    </Row>
                    <Row label={<Th>オートロック</Th>}>
                      <Td>
                        <div className="flex gap-8">
                          {radioOptions.map((item, i) => (
                            <RadioButton
                              key={i}
                              name="market_datum[auto_lock]"
                              id={`market_datum_auto_lock_${i}`}
                              text={item.text}
                              value={item.value}
                              checked={item.value === data.auto_lock}
                              onChange={(e) =>
                                setData({
                                  ...data,
                                  auto_lock: e.target.value,
                                })
                              }
                            />
                          ))}
                        </div>
                      </Td>
                    </Row>
                    {forRent && (
                      <>
                        <Row label={<Th>バルコニー</Th>}>
                          <Td>
                            <div className="flex gap-8">
                              {radioOptions.map((item, i) => (
                                <RadioButton
                                  key={i}
                                  name="market_datum[balcony]"
                                  id={`market_datum_balcony_${i}`}
                                  text={item.text}
                                  value={item.value}
                                  checked={item.value === data.balcony}
                                  onChange={(e) =>
                                    setData({
                                      ...data,
                                      balcony: e.target.value,
                                    })
                                  }
                                />
                              ))}
                            </div>
                          </Td>
                        </Row>
                        <Row label={<Th>ネット無料</Th>}>
                          <Td>
                            <div className="flex gap-8">
                              {radioOptions.map((item, i) => (
                                <RadioButton
                                  key={i}
                                  name="market_datum[free_internet]"
                                  id={`market_datum_free_internet_${i}`}
                                  text={item.text}
                                  value={item.value}
                                  checked={item.value === data.free_internet}
                                  onChange={(e) =>
                                    setData({
                                      ...data,
                                      free_internet: e.target.value,
                                    })
                                  }
                                />
                              ))}
                            </div>
                          </Td>
                        </Row>
                        <Row label={<Th>家具家電</Th>}>
                          <Td>
                            <div className="flex gap-8">
                              {radioOptions.map((item, i) => (
                                <RadioButton
                                  key={i}
                                  name="market_datum[furniture_and_appliances]"
                                  id={`market_datum_furniture_and_appliances_${i}`}
                                  text={item.text}
                                  value={item.value}
                                  checked={item.value === data.furniture_and_appliances}
                                  onChange={(e) =>
                                    setData({
                                      ...data,
                                      furniture_and_appliances: e.target.value,
                                    })
                                  }
                                />
                              ))}
                            </div>
                          </Td>
                        </Row>
                      </>
                    )}
                    {forSale && (
                      <>
                        <Row label={<Th>現況賃料(税込)/月額</Th>}>
                          <Td>
                            <div className="flex gap-4 flex-col md:flex-row">
                              <Input
                                className="md:w-[300px]"
                                value={data.current_rent || ''}
                                suffix="円"
                                placeholder=""
                                onChange={(e) =>
                                  setData({
                                    ...data,
                                    current_rent: e.target.value,
                                  })
                                }
                                onBlur={(e) => {
                                  const value = e.target.value
                                    ? cammedFormat(dataPrecisionSetup(e.target.value, 12, 0), 0)
                                    : ''
                                  setData({
                                    ...data,
                                    current_rent: value,
                                  })
                                }}
                              />
                              <input
                                type="hidden"
                                name="market_datum[current_rent]"
                                id="market_datum_current_rent"
                                value={
                                  data.current_rent ? intFormat(String(data.current_rent)) : ''
                                }
                              />
                            </div>
                          </Td>
                        </Row>
                        <Row label={<Th>現況NOI / 月額</Th>}>
                          <Td>
                            <Input
                              className="md:w-[300px]"
                              value={data.current_noi || ''}
                              suffix="円"
                              placeholder=""
                              onChange={(e) =>
                                setData({
                                  ...data,
                                  current_noi: e.target.value,
                                })
                              }
                              onBlur={(e) => {
                                const value = e.target.value
                                  ? cammedFormat(dataPrecisionSetup(e.target.value, 12, 0), 0)
                                  : ''
                                setData({
                                  ...data,
                                  current_noi: value,
                                })
                              }}
                            />
                            <input
                              type="hidden"
                              name="market_datum[current_noi]"
                              id="market_datum_current_noi"
                              value={data.current_noi ? intFormat(String(data.current_noi)) : ''}
                            />
                          </Td>
                        </Row>
                        <Row label={<Th>満室想定賃料(税込)/ 月額</Th>}>
                          <Td>
                            <Input
                              className="md:w-[300px]"
                              value={data.full_prospective_rent || ''}
                              suffix="円"
                              placeholder=""
                              onChange={(e) =>
                                setData({
                                  ...data,
                                  full_prospective_rent: e.target.value,
                                })
                              }
                              onBlur={(e) => {
                                const value = e.target.value
                                  ? cammedFormat(dataPrecisionSetup(e.target.value, 12, 0), 0)
                                  : ''
                                setData({
                                  ...data,
                                  full_prospective_rent: value,
                                })
                              }}
                            />
                            <input
                              type="hidden"
                              name="market_datum[full_prospective_rent]"
                              id="market_datum_full_prospective_rent"
                              value={
                                data.full_prospective_rent
                                  ? intFormat(String(data.full_prospective_rent))
                                  : ''
                              }
                            />
                          </Td>
                        </Row>
                        <Row label={<Th>満室想定NOI / 月額</Th>}>
                          <Td>
                            <Input
                              className="md:w-[300px]"
                              value={data.full_prospective_noi || ''}
                              suffix="円"
                              placeholder=""
                              onChange={(e) =>
                                setData({
                                  ...data,
                                  full_prospective_noi: e.target.value,
                                })
                              }
                              onBlur={(e) => {
                                const value = e.target.value
                                  ? cammedFormat(dataPrecisionSetup(e.target.value, 12, 0), 0)
                                  : ''
                                setData({
                                  ...data,
                                  full_prospective_noi: value,
                                })
                              }}
                            />
                            <input
                              type="hidden"
                              name="market_datum[full_prospective_noi]"
                              id="market_datum_full_prospective_noi"
                              value={
                                data.full_prospective_noi
                                  ? intFormat(String(data.full_prospective_noi))
                                  : ''
                              }
                            />
                          </Td>
                        </Row>
                        <Row label={<Th>売出価格</Th>}>
                          <Td>
                            <div className="flex gap-4 flex-col md:flex-row">
                              <Input
                                className="md:w-[300px]"
                                value={data.sale_price || ''}
                                suffix="円"
                                placeholder=""
                                onChange={(e) =>
                                  setData({
                                    ...data,
                                    sale_price: e.target.value,
                                  })
                                }
                                onBlur={(e) => {
                                  const value = e.target.value
                                    ? cammedFormat(dataPrecisionSetup(e.target.value, 12, 0), 0)
                                    : ''
                                  setData({
                                    ...data,
                                    sale_price: value,
                                  })
                                }}
                              />
                              <input
                                type="hidden"
                                name="market_datum[sale_price]"
                                id="market_datum_sale_price"
                                value={data.sale_price ? intFormat(String(data.sale_price)) : ''}
                              />
                              <Input
                                className="md:w-[300px]"
                                value={data.sale_prospective_yield || ''}
                                prefix="表面利回り"
                                suffix="%"
                                placeholder=""
                                onChange={(e) =>
                                  setData({
                                    ...data,
                                    sale_prospective_yield: e.target.value,
                                  })
                                }
                                onBlur={(e) => {
                                  const value = e.target.value
                                    ? dataPrecisionSetup(e.target.value, 12, 2).toFixed(2)
                                    : ''
                                  setData({
                                    ...data,
                                    sale_prospective_yield: value,
                                  })
                                }}
                              />
                              <input
                                type="hidden"
                                name="market_datum[sale_prospective_yield]"
                                id="market_datum_sale_prospective_yield"
                                value={
                                  data.sale_prospective_yield
                                    ? dataPrecisionSetup(
                                        String(
                                          formatPercentageToDecimal(data.sale_prospective_yield)
                                        ),
                                        8,
                                        4
                                      )
                                    : ''
                                }
                              />
                            </div>
                            <div className="pt-2 flex gap-4 flex-col md:flex-row">
                              <Input
                                className="md:w-[300px]"
                                value={data.sale_prospective_noi_yield || ''}
                                prefix="NOI利回り"
                                suffix="%"
                                placeholder=""
                                onChange={(e) =>
                                  setData({
                                    ...data,
                                    sale_prospective_noi_yield: e.target.value,
                                  })
                                }
                                onBlur={(e) => {
                                  const value = e.target.value
                                    ? dataPrecisionSetup(e.target.value, 12, 2).toFixed(2)
                                    : ''
                                  setData({
                                    ...data,
                                    sale_prospective_noi_yield: value,
                                  })
                                }}
                              />
                              <input
                                type="hidden"
                                name="market_datum[sale_prospective_noi_yield]"
                                id="market_datum_sale_prospective_noi_yield"
                                value={
                                  data.sale_prospective_noi_yield
                                    ? dataPrecisionSetup(
                                        String(
                                          formatPercentageToDecimal(data.sale_prospective_noi_yield)
                                        ),
                                        8,
                                        4
                                      )
                                    : ''
                                }
                              />
                            </div>
                          </Td>
                        </Row>
                        <Row label={<Th>成約価格</Th>}>
                          <Td>
                            <div className="pt-2 flex gap-4 flex-col md:flex-row">
                              <Input
                                className="md:w-[300px]"
                                value={data.building_price || ''}
                                suffix="円"
                                placeholder=""
                                onChange={(e) =>
                                  setData({
                                    ...data,
                                    building_price: e.target.value,
                                  })
                                }
                                onBlur={(e) => {
                                  const value = e.target.value
                                    ? cammedFormat(dataPrecisionSetup(e.target.value, 12, 0), 0)
                                    : ''
                                  setData({
                                    ...data,
                                    building_price: value,
                                  })
                                }}
                              />
                              <input
                                type="hidden"
                                name="market_datum[building_price]"
                                id="market_datum_building_price"
                                value={
                                  data.building_price ? intFormat(String(data.building_price)) : ''
                                }
                              />
                              <Input
                                className="md:w-[300px]"
                                value={data.prospective_yield || ''}
                                prefix="表面利回り"
                                suffix="%"
                                placeholder=""
                                onChange={(e) =>
                                  setData({
                                    ...data,
                                    prospective_yield: e.target.value,
                                  })
                                }
                                onBlur={(e) => {
                                  const value = e.target.value
                                    ? dataPrecisionSetup(e.target.value, 12, 2).toFixed(2)
                                    : ''
                                  setData({
                                    ...data,
                                    prospective_yield: value,
                                  })
                                }}
                              />
                              <input
                                type="hidden"
                                name="market_datum[prospective_yield]"
                                id="market_datum_prospective_yield"
                                value={
                                  data.prospective_yield
                                    ? dataPrecisionSetup(
                                        String(formatPercentageToDecimal(data.prospective_yield)),
                                        8,
                                        4
                                      )
                                    : ''
                                }
                              />
                            </div>
                            <div className="pt-2 flex gap-4 flex-col md:flex-row">
                              <Input
                                className="md:w-[300px]"
                                value={data.contract_prospective_noi_yield || ''}
                                prefix="NOI利回り"
                                suffix="%"
                                placeholder=""
                                onChange={(e) =>
                                  setData({
                                    ...data,
                                    contract_prospective_noi_yield: e.target.value,
                                  })
                                }
                                onBlur={(e) => {
                                  const value = e.target.value
                                    ? dataPrecisionSetup(e.target.value, 12, 2).toFixed(2)
                                    : ''
                                  setData({
                                    ...data,
                                    contract_prospective_noi_yield: value,
                                  })
                                }}
                              />
                              <input
                                type="hidden"
                                name="market_datum[contract_prospective_noi_yield]"
                                id="market_datum_contract_prospective_noi_yield"
                                value={
                                  data.contract_prospective_noi_yield
                                    ? dataPrecisionSetup(
                                        String(
                                          formatPercentageToDecimal(
                                            data.contract_prospective_noi_yield
                                          )
                                        ),
                                        8,
                                        4
                                      )
                                    : ''
                                }
                              />
                            </div>
                          </Td>
                        </Row>
                      </>
                    )}
                    <Row label={<Th>備考</Th>}>
                      <Td>
                        <Textarea
                          className="md:w-[615px]"
                          value={data?.note ?? ''}
                          name="market_datum[note]"
                          id="market_datum_note"
                          onChange={(e) =>
                            setData({
                              ...data,
                              note: e.target.value,
                            })
                          }
                        />
                      </Td>
                    </Row>
                  </tbody>
                </Table>
              </div>
            </div>
            {is_edit ||
              (!is_edit && !current_user.company.ocr_enabled && (
                <div className="lg:w-1/3 hidden lg:block">
                  {isLoaded && largeScreen && mapViewer}
                  {largeScreen && fileUploader}
                </div>
              ))}
          </div>
          <div className="bg-white flex justify-center items-center w-full left-0 md:gap-4 border-t border-gray-150 md:py-2 fixed bottom-0 md:left-12 z-20">
            {ocr.jobStatus && (
              <>
                {/* this flag intend to keep showing same screen (file previews showing up) even if form submit get validation error */}
                <input type="hidden" name="ocr_mode_enabled" value="true" />
              </>
            )}
            {ocr.jobStatus == 'preview' && current_user.company.ocr_enabled ? (
              <div className="flex gap-4">
                <Button
                  className="w-full md:w-40 md:h-auto text-gray-300"
                  variant="text"
                  onClick={(e) => {
                    e.preventDefault()
                    setOcr((prevState) => {
                      return {
                        ...prevState,
                        jobStatus: undefined,
                      }
                    })
                  }}
                >
                  キャンセル
                </Button>
                <Button
                  className="w-full md:w-40 md:h-auto !py-1"
                  variant="primaryLight"
                  disabled={!ocr.localFileValidity}
                  onClick={(e) => {
                    e.preventDefault()
                    setOcr((prevState) => {
                      return {
                        ...prevState,
                        jobStatus: 'uploadOnly',
                      }
                    })
                  }}
                >
                  資料を保存して
                  <br />
                  入力に戻る
                </Button>
                <Button
                  className="w-full md:w-40 md:h-auto"
                  disabled={!ocr.localFileValidity}
                  onClick={(e) => {
                    e.preventDefault()
                    setOcr((prevState) => {
                      return {
                        ...prevState,
                        jobStatus: 'fileUpload',
                      }
                    })
                  }}
                >
                  OCRで読取
                </Button>
              </div>
            ) : (
              <>
                <Button
                  className="w-full md:w-40 md:h-auto text-gray-300"
                  variant="text"
                  onClick={(e) => {
                    e.preventDefault()
                    setDialogOpen(true)
                  }}
                >
                  キャンセル
                </Button>
                <Button className="w-full md:w-40 md:h-auto">
                  {is_edit ? '編集を保存' : '登録'}
                </Button>
              </>
            )}
          </div>
        </OCRLayout>
      </form>

      {/* キャンセル確認のダイアログ */}
      <ConfirmDialog open={dialogOpen} onClose={onCloseDialog}>
        <div className="text-black-base text-sm text-center">
          <p>入力内容を保存せずに画面を閉じます。</p>
          <br />
          <p>よろしいですか？</p>
        </div>
      </ConfirmDialog>
    </Base>
  )
}

const location = (loc: {
  prefecture: string
  city: string
  town: string
  chome: string
}): string => {
  return `${loc.prefecture}${loc.city}${loc.town}${loc.chome}`
}

const filterErrorMessages = (error_messages: string[]) => {
  if (error_messages.includes('緯度 空欄です') || error_messages.includes('経度 空欄です')) {
    return ['地図上にピンを設定してください'].concat(
      error_messages.filter((message) => message !== '緯度 空欄です' && message !== '経度 空欄です')
    )
  }

  return error_messages
}

const mapStyle = {
  width: '100%',
  height: 'auto',
  aspectRatio: '1/1',
}

export default MarketDataNewEditPage
